import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['form', 'input', 'select2'];

    connect() {
      if (this.hasSelect2Target) {
        this.select2Targets.forEach((el) => {
          // setup events for select2 (apparently it doesn't fire standard events)
          $(el).on('select2:select', this._handleSelect2Change);
        });
      }
    }

    _handleSelect2Change(event) {
      event.target.dispatchEvent(new CustomEvent('change'));
    }

    showPopup = (event) => {
      const url = this.formTarget.dataset.popupUrl;
      const target = this.formTarget;
      // <%# Only visible if f.object.teams_default_profile_property_requirements_would_change
      //     visibility is handled by bulk-edit-function-changes#toggleRequiredProfileInputs %>

      if (url != null) {
        const data = { value_entered: this.inputTarget.value };
        if (event.submitter && event.submitter.name === 'override') {
          data.override = true;
        }

        $.ajax({
          url,
          type: 'GET',
          data,
          success(responseData, textStatus, xhr) {
            if (responseData.name !== undefined) {
              inline_edit_on_success(responseData, $(target));
            }
          },
          complete() {
            $(target).find('[type="submit"]').ladda().ladda('stop');
          },
          error(_jqXHR, textStatus, errorThrown) {
            // log the error to the console
            console.error(`Error GETing showPopup: ${textStatus}`, errorThrown);
          },
        });
      }

      const { stopSubmit } = this.formTarget.dataset;

      if (stopSubmit === 'true' || stopSubmit === true) {
        event.preventDefault();
        return false;
      }
      return true;
    }
}
