import { Controller } from 'stimulus';

// Depends on jQuery
export default class extends Controller {
  static targets = ['dateWidget',
    'dateInputsArea',
    'dateInput',
    'dateInputTemplate']

  connect() {
    const controller = this;
    $(this.dateWidgetTarget).datepicker({
      numberOfMonths: 2,
      beforeShowDay(displayDate) {
        const isDateSelected = controller.isDateSelected(displayDate);

        if (isDateSelected) {
          return [true, 'selected', ''];
        }
        return [true, '', ''];
      },
    });
  }

  selectDate = () => {
    const selectedDate = $(this.dateWidgetTarget).datepicker('getDate');
    if (this.isDateSelected(selectedDate)) {
      for (const dateInput of this.dateInputTargets) {
        if (moment(dateInput.dataset.multiDateSelectorDate).isSame(selectedDate)) {
          dateInput.remove();
          $(this.dateWidgetTarget).datepicker('setDate', null);
          return;
        }
      }
    } else {
      this.addDateSelection(selectedDate);
    }
  }

  removeDate = (event) => {
    event.preventDefault();
    event.target.closest('[data-target="multi-date-selector.dateInput"]').remove();
    $(this.dateWidgetTarget).datepicker('setDate', null);
  }

  isDateSelected(displayDate) {
    let isDateSelected = false;
    this.dateInputTargets.forEach((value) => {
      if (isDateSelected) { return; }
      if (moment(value.dataset.multiDateSelectorDate).isSame(displayDate)) {
        isDateSelected = true;
      }
    });
    return isDateSelected;
  }

  addDateSelection(selectedDate) {
    const dateInput = this.dateInputTemplateTarget.cloneNode(true);

    dateInput.querySelectorAll('input')[0].value = moment(selectedDate).format('D-M-Y');
    dateInput.classList.remove('hidden');
    dateInput.querySelectorAll('[data-target="multi-date-selector.dateInputLabel"]')[0].innerHTML = moment(selectedDate).format('D-M-Y');
    dateInput.dataset.target = 'multi-date-selector.dateInput';
    dateInput.dataset.multiDateSelectorDate = moment(selectedDate).format('YYYY-MM-DD');

    this.dateInputsAreaTarget.appendChild(dateInput);
  }
}
