import { Controller } from 'stimulus';

// controller: admin--tenant--enrolments
export default class extends Controller {
  static targets = [
    'teamEnrollmentNeedsConfirmationInputWrapper',
    'teamEnrollmentViaPlannerNeedsConfirmationInputWrapper',
    'unconfirmedEnrolmentsCanBeOverlappedInputWrapper',
  ]

  connect() {
    this.toggleUnconfirmedEnrolmentsCanBeOverlappedInputWrapperVisibility();
  }

  toggleUnconfirmedEnrolmentsCanBeOverlappedInputWrapperVisibility() {
    if (this.teamEnrollmentNeedsConfirmationEnabled() || this.teamEnrollmentViaPlannerNeedsConfirmationEnabled()) {
      this.showUnconfirmedEnrolmentsCanBeOverlappedInputWrapper();
    } else {
      this.hideUnconfirmedEnrolmentsCanBeOverlappedInputWrapper();
    }
  }

  showUnconfirmedEnrolmentsCanBeOverlappedInputWrapper() {
    this.unconfirmedEnrolmentsCanBeOverlappedInputWrapperTarget.classList.remove('hidden');
  }

  hideUnconfirmedEnrolmentsCanBeOverlappedInputWrapper() {
    this.unconfirmedEnrolmentsCanBeOverlappedInputWrapperTarget.classList.add('hidden');
  }

  teamEnrollmentNeedsConfirmationEnabled() {
    if (this.hasTeamEnrollmentNeedsConfirmationInputWrapperTarget) {
      const checkedInput = this.teamEnrollmentNeedsConfirmationInputWrapperTarget.querySelector('input:checked');
      return checkedInput.value == 'true';
    }

    return false;
  }

  teamEnrollmentViaPlannerNeedsConfirmationEnabled() {
    if (this.hasTeamEnrollmentViaPlannerNeedsConfirmationInputWrapperTarget) {
      const checkedInput = this.teamEnrollmentViaPlannerNeedsConfirmationInputWrapperTarget.querySelector('input:checked');
      return checkedInput.value == 'true';
    }

    return false;
  }
}
